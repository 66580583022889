import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Content from 'components/Content'
import Hero from 'components/Hero'

import 'styles/ContactPage.scss'
import GravityForm from '../components/GravityForm'

const HeroContent = (hero) => (
  <Content className="mb-4" content={hero.description} />
)

const JoinTheTeam = ({ data }) => {
  const { wordpressPage: page, gfForm } = data
  const {
    acf: { hero, form },
  } = page

  return (
    <Layout>
      <SEO
        yoast={{ meta: page.yoast_meta }}
        lang={page.wpml_current_locale.slice(0, 2)}
      />
      <div className="contact-page">
        <Hero
          className="contact-hero"
          fluidImage={hero.image.localFile.childImageSharp.fluid}
          title={hero.title}
          description={HeroContent(hero)}
        />

        <section className="pb-lg-5">
          <div className="container pt-lg-5 pb-5">
            <div className="card form-card py-5 px-3 px-lg-0">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <GravityForm
                    id={7}
                    perColumn={3}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default JoinTheTeam

export const query = graphql`
  query JoinTheTeamPageById($wordpress_id: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      id
      wordpress_id
      title
      slug
      content
      wpml_current_locale
      yoast_meta {
        name
        content
        property
      }
      acf {
        hero {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          title
          description
        }
      }
    }
  }
`
